<template>
  <div class="main-block">
    <div class="main-block-title">Personal information</div>
    <div class="main-block-content">
      <div class="grid">

        <form-group label="First Name" :value="form.first_name" :errors="errors.first_name">
          <input type="text" data-test="first_name" v-model="form.first_name" @change="validate(['first_name'])" class="form-control" autocomplete="given-name">
        </form-group>

        <form-group label="Last Name" :value="form.last_name" :errors="errors.last_name">
          <input type="text" data-test="last_name" v-model="form.last_name" @change="validate(['last_name'])" class="form-control" autocomplete="family-name">
        </form-group>

      </div>
    </div>

    <div class="main-block-title">Address details</div>
    <div class="main-block-content">
      <div class="grid">

        <form-group label="Country" :value="form.country_id" :errors="errors.country_id">
          <v-select :options="countries" data-test="country" @input="validate(['country_id'])" :reduce="option => option.id" v-model="form.country_id" label="label" class="form-control"></v-select>
        </form-group>

        <form-group label="City" :value="form.city" :errors="errors.city">
          <input v-model="form.city" data-test="city" @change="validate(['city'])" type="text" class="form-control"/>
        </form-group>

        <form-group label="Street" :value="form.street" :errors="errors.street">
          <input v-model="form.street" data-test="street" @change="validate(['street'])" type="text" class="form-control"/>
        </form-group>

        <form-group label="House" :value="form.house" :errors="errors.house">
          <input v-model="form.house" data-test="house" @change="validate(['house'])" type="text" class="form-control"/>
        </form-group>

        <form-group label="Flat" :value="form.apartment" :errors="errors.apartment">
          <input v-model="form.apartment" data-test="apartment" @change="validate(['apartment'])" type="text" class="form-control"/>
        </form-group>

        <form-group label="Postal Code" :value="form.postal_code" :errors="errors.postal_code">
          <input v-model="form.postal_code" data-test="postal_code" @change="validate(['postal_code'])" type="text" class="form-control"/>
        </form-group>

      </div>

      <div class="custom-hr"><span>Please add the address confirmation documents</span></div>

      <file-upload
          label="Address Confirmation"
          data-test="address_files"
          v-model="form.address_confirmation_files"
          :types="addressTypeList"
          @change="validate(['address_confirmation_files'])"
          name="address_confirmation_files"
          :errors="errors"
      />

      <div class="custom-hr"><span>Please add the identity documents</span></div>

      <file-upload
          label="Identity Document"
          data-test="identity_files"
          v-model="form.id_document_files"
          :types="walletTypeList"
          @change="validate(['id_document_files'])"
          name="id_document_files"
          :errors="errors"
      />

      <div class="custom-hr"/>

      <div class="main-block-title">PEP/FATCA &nbsp; <icon-info color="#19191C" size="15" @click.native="openModal"/></div>

      <div class="grid">
        <div>
          <form-group label="Does the Person qualify as a PEP?" :value="form.is_pep" :errors="errors.is_pep">
            <label data-test="is_pep" class="form-control-custom" v-for="item in boolAnswers" :key="item.id">
              <input type="radio" v-model="form.is_pep" :value="item.id" @change="validate(['is_pep'])">
              <span class="form-control">{{ item.label }}</span>
            </label>
          </form-group>
        </div>

        <div>
          <form-group label="Does the Person qualify as a U.S. person?" :value="form.is_us" :errors="errors.is_us">
            <label data-test="is_us" class="form-control-custom" v-for="item in boolAnswers" :key="item.id">
              <input type="radio" v-model="form.is_us" :value="item.id" @change="validate(['is_us'])">
              <span class="form-control">{{ item.label }}</span>
            </label>
          </form-group>
        </div>
      </div>

      <transition name="fade">
        <pep-fatca-modal v-show="modal" v-on:close="closeModal"/>
      </transition>

      <div class="additional-verification">
        <div class="bold">Get one step closer to upgrading to a Premium account</div>
        <label class="form-control-custom" style="margin-bottom: 0; font-size: 12px;">
          <input type="checkbox" v-model="agree_ondata_verification" :value="1" data-test="agree">
          <span class="form-control">By ticking the box I agree to complete a live ID-verification</span>
        </label>
      </div>

      <div class="form-buttons text-right">
        <button type="submit" class="btn-next" @click="save" data-test="submit">
          <span>Save and proceed</span>
          <icon-next color="#19191C" size="15"/>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import IconNext from "@/assets/icons/IconNext";
import FormGroup from "@/components/FormGroup";
import {mapGetters, mapState} from "vuex";
import FileUpload from "../../components/FileUpload";
import PepFatcaModal from "@/views/modals/PepFatcaModal.vue";
import IconInfo from "@/assets/icons/IconInfo.vue";

export default {
  name: 'ContactsInfo',
  components: {
    IconInfo,
    PepFatcaModal,
    FileUpload,
    IconNext,
    FormGroup
  },
  data () {
    return {
      modal: false,

      agree_ondata_verification: false,

      form: {
        middle_name: null,
        last_name: null,
        first_name: null,
        country_id: undefined,
        city: null,
        street: null,
        house: null,
        apartment: null,
        postal_code: null,
        address_confirmation_files: [],
        id_document_files: [],
        is_pep: '',
        is_us: '',
      },

      errors: {}
    }
  },
  mounted () {
    const data = this.getRegistrationDataPart('company-wallet').attributes

    if (data) {
      this.form = data
    }
  },
  computed: {
    ...mapState({
      countries: state => state.init.countries,
      boolAnswers: state => state.init.boolAnswers,
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      // Default list data
      walletTypeList: state => state.init.companyIndividualIDDocumentFileType,
      addressTypeList: state => state.init.companyIndividualAddressConfirmationFileType,

    }),
    ...mapGetters({
      countriesSorted: 'listSorted',
      getRegistrationDataPart: 'getRegistrationDataPart'
    }),
    formData () {
      return {
        middle_name: this.form.middle_name || null,
        last_name: this.form.last_name,
        first_name: this.form.first_name,
        country_id: this.form.country_id || '',
        city: this.form.city,
        street: this.form.street,
        house: this.form.house,
        apartment: this.form.apartment,
        postal_code: this.form.postal_code,
        address_confirmation_files: this.form.address_confirmation_files,
        id_document_files: this.form.id_document_files,
        is_pep: this.form.is_pep,
        is_us: this.form.is_us,
      }
    }
  },
  methods: {
    openModal () {
      this.modal = true
    },
    closeModal () {
      this.modal = false
    },
    validate (attributes = []) {

      for (let name of ['id_document_files', 'address_confirmation_files']) {
        let filesIndex = attributes.indexOf(name)
        if (filesIndex !== -1) {
          for (let i in this.formData[name]) {
            attributes.push(name + '[' + i + '][type]')
            attributes.push(name + '[' + i + '][name]')
            attributes.push(name + '[' + i + '][original_name]')
          }
        }
      }

      return this.$sdk.registration.wallet.update(this.registrationHash, this.formData, 1).then(response => {
        for (const attr of attributes) {
          if (response.data[attr] !== undefined) {
            this.$set(this.errors, attr, response.data[attr])
          } else if (this.errors[attr] !== undefined) {
            this.$delete(this.errors, attr)
          }
        }
      })
    },
    save () {
      return this.$sdk.registration.wallet.update(this.registrationHash, this.formData, 0).then(response => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$store.dispatch('updateRegistration').then(() => {
            if (this.agree_ondata_verification) {
              this.$router.push({
                name: 'Verification',
                params: {
                  hash: this.registrationHash
                }
              })
            } else {
              this.$emit('next-step', {
                skipCompleted: true
              })
            }
          })
        } else {
          this.errors = response.data
        }
      })
    }
  }
}
</script>

<style lang="less">
.additional-verification {
  margin-top: 60px;

  label {
    margin-top: 10px;
    margin-left: -13px;
  }
}

</style>
